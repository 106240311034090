import { ReactElement } from 'react';
import { CabButton, CabIcon } from '@CabComponents';
import colors from '../../colors';
import { Box, Typography } from '@mui/material';
import { IoAddCircleOutline } from 'react-icons/io5';


interface Props {
  id: string | number;
  src: string;
  label: string;
  alt?: string;
  onClick: (id: string | number) => void;
  isDefault: boolean;
}

export const PickerOption = ({ id, src, label, alt, onClick, isDefault}: Props): ReactElement => {

  const renderImgOrIcon = (): ReactElement => {
    if (isDefault) {
      return <>
        <Box component={'img'} src={src} alt={alt} maxHeight={42} maxWidth={42} 
          marginTop={.5} marginBottom={.5} />
        <Typography fontSize={12} fontWeight={'bold'}>{label}</Typography>
      </>;
    } else {
      return  <>
        <CabIcon Icon={IoAddCircleOutline} 
          sx={{fontSize: 42, marginTop: .75, marginBottom: .75}} />
        <Typography fontSize={12} fontWeight={'bold'}>{label}</Typography>
      </>;
    }
  };

  return (
    <Box justifyContent='center' display='flex'>
      <CabButton
        buttonType='secondary'
        sx={{
          backgroundColor: "transparent",
          color: colors.black900,
          height: 'auto',
          width: '100%',
          "&:hover": {
            backgroundColor: colors.black100,
            boxShadow: "none"
          }
        }} 
        onClick={(): void => onClick(id)}>
        {renderImgOrIcon()}
      </CabButton>
    </Box>
  );
};

export default PickerOption;